import React from 'react'
 
import linkedinIcon from '../../assets/icons/icon-linkedin-blue.png';
import './team.scss'
 
const Team = (props) => {
    const data = props.data 
   
    return (
        <div  className="team">
            {data.map((member=>(              
                    <div className="team-card" key={member.Title}>                  
                    <img src={member.Image.publicURL} alt={member.MemberName}></img>   
                    <h4 className="divider">{member.MemberName}</h4>
                    <h5>{member.Title}</h5>
                    <p>{member.Bio}</p>
                    <a href={member.LinkedinUrl} target="_blank" rel="noreferrer">
                        <img src={linkedinIcon} alt="linked" />
                    </a>
                </div> 
            )))}         
        </div>
    )
}

export default Team
