import React, { useEffect } from "react";
import Team from "../components/about/Team";
import Layout from "../components/layout";
import PageBannerAboutUs from "../components/pageBannerAboutUs";
import OurPurpose from "../components/about/OurPurpose";
import SectionHeader from "../components/sectionHeader";
import Zoom from "react-reveal/Zoom";
import Fade from "react-reveal/Fade";
import { Helmet } from "react-helmet";
import { Container } from "react-bootstrap";
import { useStaticQuery, graphql } from "gatsby";

import "../styles/about.scss";
import OurStory from "../components/about/OurStory";
import Nexware from "../components/about/Nexware";

function AboutUs() {
  const cards =
    typeof document !== `undefined`
      ? document.querySelectorAll(".team-card")
      : null;

  useEffect(() => {
    for (const card of cards) {
      card.addEventListener("mouseenter", () => {
        card.classList.add("hovered");
      });

      card.addEventListener("mouseleave", () => {
        card.classList.remove("hovered");
      });
    }
  }, [cards]);

  const aboutPageData = useStaticQuery(graphql`
    query {
      allStrapiAboutPage {
        nodes {
          PageTitle
          OurPurposeComponent {
            Content
            ContentH4
            OurPurposeSectionHeader {
              HightLightText
              Text
              id
              Icon {
                publicURL
                id
              }
            }
            Title
            TitleHighlight
            id
          }
          OurStory {
            AnchorLinkText
            Content
            ContentHighlight
            Title
            TitleHighlight
            id
          }
          Nexware {
            Content
            id
          }
          OurTeamComponent {
            Bio
            LinkedinUrl
            Title
            MemberName
            id
            Image {
              publicURL
              id
            }
            id
          }
          PageBannerAboutUs {
            Image {
              id
              publicURL
            }
          }
          id
        }
        pageInfo {
          perPage
        }
      }
    }
  `);

  const banner =
    aboutPageData.allStrapiAboutPage.nodes[0].PageBannerAboutUs[0].Image
      .publicURL;

  return (
    <Layout>
      <Helmet
        title={aboutPageData.allStrapiAboutPage.nodes[0].PageTitle}
        defer={false}
      />

      <Container className="about-hero__banner page-banner">
        <Fade bottom>
          <PageBannerAboutUs image={banner} />
        </Fade>
      </Container>

      <Container className="about">
        <Zoom left>
          <OurStory
            data={aboutPageData.allStrapiAboutPage.nodes[0].OurStory[0]}
          />
        </Zoom>
        {/* <Zoom left>
                    <Nexware data={aboutPageData.allStrapiAboutPage.nodes[0].Nexware[0]}/>
                </Zoom> */}

        <Fade right>
          <OurPurpose
            data={
              aboutPageData.allStrapiAboutPage.nodes[0].OurPurposeComponent[0]
            }
          />
        </Fade>

        <div className="about-title">
          <SectionHeader title="Our  " highlight="Leadership Team." />
        </div>

        <Team
          data={aboutPageData.allStrapiAboutPage.nodes[0].OurTeamComponent}
        />
      </Container>
    </Layout>
  );
}

export default AboutUs;
