import React, { useState } from "react";
import PlayIcon from "./../assets/icons/icon-play.png";
import PlayVideo from "./../assets/myvid.mp4";
// import YouTube from 'react-youtube';
import { Modal } from "react-bootstrap";
import styled from "styled-components"

const PageBannerAboutUs = (props) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const opts = {
    
    playerVars: {
      autoplay: 1,
    },
  };

  return (
    <Styles>
        <div className="page-banner">
          <Modal show={show} size="lg" centered onHide={handleClose} className="video-modal" >
            <Modal.Header closeButton />
            <Modal.Body>
    <video width="100%" height="100%"  preload='auto' autoPlay  >
 <source src={PlayVideo} type="video/mp4" />
</video>
            </Modal.Body>
          </Modal>

          <a onClick={handleShow}>
            <img src={PlayIcon} alt="Play" width={40} className="play-icon" />
          </a>
          <img src={props.image} alt='banner' width="100%"></img>
        </div>
    </Styles>
  );
};

const Styles = styled.div`
  a {
    cursor: pointer;
  }
`

export default PageBannerAboutUs;
