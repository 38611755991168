import React from "react";
import ReactMarkdown from "react-markdown";
import SectionHeader from "../../components/sectionHeader";
import ideasIcon from "../../assets/icons/icon-ideas.png";
//import colan from "../../assets/icons/colan.png";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { Container, Col, Row } from "react-bootstrap";
import "./ourStory.scss";
import "../../components/our-story1.scss";

const OurStory = (pageData) => {
  const content = pageData.data.Content;
  return (
    <div className="row mt-5">
      <div className="col-12 col-sm-8 our-story">
        <SectionHeader
          icon={ideasIcon}
          title={pageData.data.Title}
          highlight={pageData.data.TitleHighlight}
        />
        {/* <div className="colan"> */}
        {/* <img src={colan} alt="colan" width="60"></img>    */}
        {/* </div>  */}
        <ReactMarkdown source={content} />
        <AnchorLink to="/#book" className="btn btn-thick xyz-nested ">
          {pageData.data.AnchorLinkText}
        </AnchorLink>
      </div>
      <div className="col-12 col-sm-4">
        <section className="our-story1__cardtext">
          <ReactMarkdown
            className="highlight"
            source={pageData.data.ContentHighlight}
          />
        </section>
      </div>
    </div>
  );
};
export default OurStory;
