import React from "react";
import purposeIcon from "../../assets/icons/icon-purpose.png";
import raiseIcon from "../../assets/icons/icon-raise.png";
import screenIcon from "../../assets/icons/icon-screen.png";
import speedIcon from "../../assets/icons/icon-speed.png";

import SectionHeader from "../../components/sectionHeader";
import { Col, Row } from "react-bootstrap";

import "./ourPurpose.scss";

const OurPurpose = (props) => {
  const data = props.data;

  return (
    <div className="our-purpose mt-5">
      <SectionHeader
        icon={purposeIcon}
        title={data.Title}
        highlight={data.TitleHighlight}
      />
      <div>
        {data.Content}
        <h4>{data.ContentH4} </h4>
      </div>
      <Row>
        <Col md>
          <div className="our-purpose__card">
            <SectionHeader
              icon={raiseIcon}
              highlight={data.OurPurposeSectionHeader[0].HightLightText}
              text={data.OurPurposeSectionHeader[0].Text}
            />
          </div>
        </Col>
        <Col md>
          <div className="our-purpose__card">
            <SectionHeader
              icon={screenIcon}
              highlight={data.OurPurposeSectionHeader[1].HightLightText}
              text={data.OurPurposeSectionHeader[1].Text}
            />
          </div>
        </Col>
        <Col md>
          <div className="our-purpose__card">
            <SectionHeader
              icon={speedIcon}
              highlight={data.OurPurposeSectionHeader[2].HightLightText}
              text={data.OurPurposeSectionHeader[2].Text}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default OurPurpose;
